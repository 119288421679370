.awsPlayerWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .playerInfos {
    height: 75px;
  }

  .awsPlayer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .duration {
      font-family: Quicksand-Medium, serif;
      margin-left: 20px;
      font-size: 12px;
    }

    .sb-soundplayer-btn {
      display: inline-block;
      background-color: transparent;
      color: $grey;
      font-size: 1em;
      margin: 0;
      height: auto;
      vertical-align: middle;
      border: 0;
      outline: none;
      cursor: pointer;
      -webkit-transition: 0.05s ease, -webkit-transform, -webkit-box-shadow;
      transition: 0.05s ease, -webkit-transform, -webkit-box-shadow;
      transition: transform, box-shadow, 0.05s ease;
      transition: transform, box-shadow, 0.05s ease, -webkit-transform,
        -webkit-box-shadow;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
    }
    .sb-soundplayer-btn:hover {
      -webkit-transform: scale(1.0625);
      transform: scale(1.0625);
    }
    .sb-soundplayer-btn:focus {
      -webkit-transform: scale(0.9375);
      transform: scale(0.9375);
    }
    .sb-soundplayer-btn:active {
      -webkit-box-shadow: inset 0 0 0 2px $grey;
      box-shadow: inset 0 0 0 2px $grey;
    }
    .sb-soundplayer-btn:hover,
    .sb-soundplayer-btn:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .sb-soundplayer-icon {
      width: 20px;
      height: 20px;
    }
  }
}
