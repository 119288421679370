.root {
  @import "reset";

  @media screen and (min-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    height: 100vh;

  }
  

  //COLORS
  $black: #1d2324;
  $white: #ffffff;
  $main: #2896a5;
  $lightgrey: #c8c8ca;
  $lightblue: #ebf3f9;
  $grey: grey;

  .Header {
    margin-top: 5vh;
    .headerImg {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .headerLogo {
        width: 175px;
        margin-right: 16px;
      }
      .headerPicto {
        height: 50px;
      }
    }
  }

  .Body {
    margin: 20px 0;
    .bodyHeader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin: 3vh 0;
    

      .headphonePicto {
        height: 75px;
        margin-right: 2vw;
      }

      .text {
        .title {
          font-family: Quicksand-Bold, serif;
          color: $main;
          margin: 0;
          font-size: 20px;
        }
        .details {
          font-family: Cursive-Standard, serif;
          margin-top: 5px;
          line-height: 1.2;
        }
      }
    }

    .playerSection {
      background-color: $lightblue;
      @media screen and (max-width: 350px) {
        padding: 0;
        background-color: $white;
      }
      @media screen and (max-width: 450px) {
        padding: 4vh 0 2vh 0;
      }
      @media screen and (min-width: 451px) {
        padding: 4vh 50px 2vh 50px;
      }

      

      .playerWithCover {
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        .playerWrapper {
          @import "player";
          width: 200px;
          height: 125px;
          background-color: $white;
          padding-left: 16px;
          .title {
            font-family: Quicksand-Bold, serif;
            font-size: 18px;
            margin-right: 10px;
          }
          .author {
            font-family: Quicksand-Medium, serif;
            font-size: 14px;
          }
        }
        .storyCover {
          height: 125px;
          width: 125px;
        }
      }
      .disclaimer {
        font-size: 10px;
        color: $grey;
        margin: 1vh 0;
        font-family: Quicksand-Medium, serif;
        text-align: center;
      }
    }
  }

  .Footer {


    .textSection {
      text-align: justify;
      font-family: Quicksand-Medium, serif;
      margin: auto;
      margin-bottom: 2vh;
      width: 90vw;
      max-width: 430px;
      font-size: 13px;
      .boldText {
        font-weight: bold;
        text-align: center;
        margin-top: 3vh;
        @media screen and (max-width: 350px) {
          margin-top: 1vh;
        }
        .emoji {
          vertical-align: top;
        }
      }
    }
    .btnSection {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 3vh;
      .btn {
        font-family: Quicksand-Bold, serif;
        text-transform: none;
        color: grey;
      }
    }
  }
}
